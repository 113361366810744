<template>
  <div class="my">
    <van-loading type="spinner" v-if="loading" color="#999" vertical>加载中...</van-loading>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <template #pulling="props">
        <div class="refresh">加载中...</div>
      </template>
      <template #loosing>
        <div class="refresh">加载中...</div>
      </template>
      <div v-if="!loading" class="all_content_box">
        <div class="top_box">
          <div class="title">
            <div class="name_info">
              <div v-if="userInfo && userInfo.nickName" class="info_box">
                <img class="userImg" :src="userInfo.avatarUrl">
                <p class="userName">{{ userInfo.nickName }}</p>
              </div>
              <div v-else class="info_box">
                <img @click="goLogin" class="userImg" src="../../sprite/noLogin-icon.jpg">
                <p @click="goLogin" class="userName">点击登录</p>
              </div>
              <!-- <div class="raiders_box">
              <p @click="goGrade">升级攻略</p>
            </div> -->
            </div>
          </div>
        </div>
        <div class="amount">
          <div class="amountIn">
            <div class="myProfit">
              <div class="myProfitTitle">
                <span class="ti">我的总收益(元)</span>
                <span @click="goCommission('com')">奖励明细
                  <van-icon name="arrow" />
                </span>
              </div>
              <div class="extractItem">
                <h5 class="money">{{ userAccountInfo.totalAmount || 0 }}</h5>
                <span class="goExtract" @click="handleWithdrawal()">去提现</span>
              </div>
            </div>
            <div class="moneyItem">
              <div>
                <span class="popoverTip">待结算</span>
                <b>{{ amountText(userAccountInfo.waitSettlementAmount || 0) }}<i
                    v-if="userAccountInfo.waitSettlementAmount >= 10000">万元</i></b>
              </div>
              <div style="padding: 0;">
                <span>可提现</span>
                <b>{{ amountText(userAccountInfo.balance || 0) }}<i v-if="userAccountInfo.balance >= 10000">万元</i></b>
              </div>
              <div>
                <span>提现中</span>
                <b>{{ amountText(userAccountInfo.frozenAmount || 0) }}<i
                    v-if="userAccountInfo.frozenAmount >= 10000">万元</i></b>
              </div>
              <div>
                <span>已提现</span>
                <b>{{ amountText(userAccountInfo.finishAmount || 0) }}<i
                    v-if="userAccountInfo.finishAmount >= 10000">万元</i></b>
              </div>
            </div>
          </div>
        </div>
        <div class="order_num_box">
          <div class="order_title">
            <span>分佣订单</span>
            <span @click="goCommission('order')">明细
              <van-icon name="arrow" />
            </span>
          </div>
          <ul>
            <li>
              <p>总订单数</p>
              <p>{{ amountText(orderSummary.totalOrder || 0) }}<i v-if="orderSummary.totalOrder >= 10000">万</i></p>
            </li>
            <li>
              <p>我的订单</p>
              <p>{{ amountText(orderSummary.myOrder || 0) }}<i v-if="orderSummary.myOrder >= 10000">万</i></p>
            </li>
            <li>
              <p>团队订单</p>
              <p>{{ amountText(orderSummary.teamOrder || 0) }}<i v-if="orderSummary.teamOrder >= 10000">万</i></p>
            </li>
          </ul>
        </div>
        <div class="serveList">
          <div class="serveListIn">
            <div class="item" @click="showInvite">
              <div>
                <img class="icon" src="../../sprite/myServeItem-icon1.jpg">
                <span class="item1">邀请好友</span>
              </div>
              <div>
                <span class="tip">好友售卡，你也赚钱</span>
                <img class="arrow_icon" src="../../sprite/arrow_icon.png" alt="">
              </div>
            </div>
            <div class="item" @click="goItem('/eventRewards')">
              <div>
                <img class="icon" src="../../sprite/myServeItem-icon2.jpg">
                <span class="item0">活动奖励</span>
              </div>
              <img class="arrow_icon" src="../../sprite/arrow_icon.png" alt="">
            </div>
            <div class="item" @click="goItem('/withdrawalRecord')">
              <div>
                <img class="icon" src="../../sprite/myServeItem-icon3.jpg">
                <span class="item2">提现记录</span>
              </div>
              <img class="arrow_icon" src="../../sprite/arrow_icon.png" alt="">
            </div>
            <div class="item" @click="goItem('/myTeam')">
              <div>
                <img class="icon" src="../../sprite/myServeItem-icon4.jpg">
                <span class="item3">我的团队</span>
              </div>
              <div>
                <span class="tip">{{ teamListTotal }}人</span>
                <img class="arrow_icon" src="../../sprite/arrow_icon.png" alt="">
              </div>
            </div>
            <div class="item" @click="handleCustomer">
              <div>
                <img class="icon" src="../../sprite/myServeItem-icon5.jpg">
                <span class="item4">联系客服</span>
              </div>
              <img class="arrow_icon" src="../../sprite/arrow_icon.png" alt="">
            </div>
          </div>
        </div>
        <div v-if="userInfo && userInfo.nickName" @click="handleSignOut" class="outLogin">
          <span>退出登录</span>
        </div>
      </div>
    </van-pull-refresh>
    <!-- 联系客服 -->
    <van-popup v-model="welfareShow" :closeable="false" :round="true" :close-on-click-overlay="false" overlay
      class="welfareAccount">
      <div class="mengKeWarp">
        <img src="@/sprite/customer_service.jpg" alt="" />
        <img class="QRcode" src="@/sprite/customer_service_QRcode.jpg" />
      </div>
      <!-- <van-icon name="close"
                size="0.66667rem"
                color="#fff"
                class="iconClose"
                 /> -->
      <span class="iconClose" @click="welfareShow = false"></span>
    </van-popup>
    <!-- 邀请好友海报 -->
    <InviteFriendsPopup v-if="showInviteFriendsPopup" :showPosterInvite="showInviteFriendsPopup"
      :superiorUrlInvite="superiorShortUrlInvite" pageType="my" @closePopup="handleClose"></InviteFriendsPopup>

    <div class="joinDialog"
            :class="isVisible?'showDialog':'hiddenDialog'">
          <div class="content">
              <div class="joinIn">
                  <div class="titleTop">
                      <img src="../../sprite/joinDialog-bg.png" >
                  </div>
                  <div class="conter">
                      <div class="user">
                          <img :src="userInfo && userInfo.avatarUrl" alt="">
                          <span>{{userInfo && userInfo.nickName}}</span>
                      </div>
                      <img class="tip" src="../../sprite/joinDialog-tip.jpg" >
                      <img class="enterBtn" @click="createUserInfo" src="../../sprite/joinDialog-btn.png">
                      <div class="refuse" @click="isVisible = false">拒绝协议</div>
                      <div class="agreement">我已同意以下协议：
                          <span @click="viewAgreement('/serveAgreement')">《酷联盟推广大使服务协议》</span>
                          <span @click="viewAgreement('/commissionAgreement')">《酷联盟推广大使佣金与行为规则》</span>
                      </div>
                  </div>
              </div>
              <div class="close"><span @click="isVisible = false" ></span></div>
          </div>
      </div>
    <v-tabbar :isAuthorized="true"></v-tabbar>
  </div>
</template>

<script>
import { Dialog, Popup } from 'vant'
import './index.less'
import {
  setToken,
  getToken,
  urlParse
} from '@/utils/tokenUtils'
import {
  GetUserOrdeGetAllUserOrderSummaryrSummary,
  Logout,
  GetUserInfo,
  GetUserAccountInfo,
  GetTeamList
} from '@/service/my'
import { CreateUserInfo, GetShortLink } from '@/service/home'
import { mapState, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
export default {
  components: {
    vanDialog: Dialog,
    VanPopup: Popup
  },
  data() {
    return {
      userInfo: {},
      userAccountInfo: {},
      loading: false,
      orderSummary: {}, // 盟主订单数据
      mountPointInfo: {}, // 大使芒点信息
      progressNum: 0, // 等级进度条的值
      teamListTotal: 0, // 团队人数
      welfareShow: false, // 控制 “加入福利群” 显隐
      superiorLongUrlInvite:'',
      superiorShortUrlInvite: '', // 存储 邀请好友链接
      showInviteFriendsPopup: false, // 邀请海报显隐
      urlData: '',
      isLoading: false,
      isVisible:false,
    }
  },
  computed: {
    // 当超过 10000 时，截取后用万单位来显示
    amountText() {
      return (value) => {
        if (value < 10000) {
          return value
        } else {
          return Math.floor((value / 10000) * 100) / 100
        }
      }
    },
  },
  created() {

    this.urlData = urlParse(window.location.href)
    if (this.urlData.token) {
      setToken('token', this.urlData.token)
      this.init();
      return;
    }
    if (getToken('token')) {
      this.init();
    }
    this.getUserInfo()
  },
  mounted() {

  },
  methods: {
    onRefresh() {
      if (this.urlData.token) {
        setToken('token', this.urlData.token)
        this.init();
        return;
      }
      if (getToken('token')) {
        this.init();
      }
      this.getUserInfo()
    },
    goLogin() {
      let superiorId = this.urlData.superiorId ? this.urlData.superiorId : '';
      window.location.href = `https://t.youku.com/yep/page/m/jca2kbelhq?wh_weex=true&isNeedBaseImage=1&redirect_url=${process.env.NODE_ENV == 'development' ? encodeURIComponent(window.location.href + '?superiorId=' + superiorId) : encodeURIComponent(window.location.href + '?superiorId=' + superiorId)
        }`
    },
    // 点击 升级攻略
    goGrade() {
      this.$router.push('/grade')
    },
    // 退出登录
    handleSignOut() {
      let that = this
      Dialog.confirm({
        message: '确认退出登录吗？',
        confirmButtonColor: '#333333',
        cancelButtonColor: '#999999'
      })
        .then(() => {
          Logout().then((res) => {
            const { code, data, message } = res
            if (code == '0') {
              localStorage.removeItem('token')
              localStorage.clear()
              window.location.href = window.location.href.split('?')[0];
              location.reload();
            } else {
              that.$toast(message)
            }
          })
        })
        .catch(() => { })
    },
    async init() {
      this.loading = true
      await this.getUserInfo() // 盟客基础信息
      await this.getUserAccountInfo() // 盟客账户信息
      await this.getUserOrderSummary()
      await this.getTeamList()
    },
    getUserInfo() {
      GetUserInfo().then((res) => {
        const { code, data, message } = res
        this.loading = false
        if (code == '0') {
          console.log(data)
          this.userInfo = data
          localStorage.setItem('nickName', data.nickName)
          localStorage.setItem('avatarUrl', data.avatarUrl)
          localStorage.setItem('channelCode', data.channelCode)
          localStorage.setItem('mgUuid', data.mgUuid)
          localStorage.setItem('id', data.id)
          localStorage.setItem('shareId', data.shareId)
          localStorage.setItem('superiorId', data.superiorId)
          localStorage.setItem('userLevel', data.userLevel)
          localStorage.setItem('isAuth', true)
          let url = window.location.href.split('#')[0]
          this.superiorLongUrlInvite =
            process.env.NODE_ENV == 'development'
              ? `http://test.youku.com/#/shareExtension?nickName=${encodeURIComponent(
                this.userInfo.nickName
              )}&superiorId=${this.userInfo.id}`
              : `${url}#/shareExtension?nickName=${encodeURIComponent(
                this.userInfo.nickName
              )}&superiorId=${this.userInfo.id}`
          // this.getShortLink(this.superiorLongUrlInvite)
        } else if (code == '402') {
          this.userInfo = data
          this.isVisible = true;
          // this.createUserInfo()
        } else if (code == '-1') {
          // this.userInfo = data
          // localStorage.setItem('nickName', data.nickName)
          // localStorage.setItem('avatarUrl', data.avatarUrl)
          // localStorage.setItem('channelCode', data.channelCode)
          // localStorage.setItem('mgUuid', data.mgUuid)
          // localStorage.setItem('id', data.id)
          // localStorage.setItem('shareId', data.shareId)
          // localStorage.setItem('superiorId', data.superiorId)
          // localStorage.setItem('userLevel', data.userLevel)
          // this.getUserAccountInfo()
          localStorage.clear()
          this.$toast(message)
        } else if (code == '401' && message == '未授权') {
          localStorage.clear()
        }
        this.isLoading = false;
      })
    },
    createUserInfo() {
      CreateUserInfo({
        token: localStorage.getItem('token'),
        channelCode: this.urlData.channelCode,
        superiorId: this.urlData.superiorId,
        shareId: this.urlData.shareId
      }).then((res) => {
        const { code, data } = res
        if (code == '0') {
          this.userInfo = data
          localStorage.setItem('nickName', data.nickName)
          localStorage.setItem('avatarUrl', data.avatarUrl)
          localStorage.setItem('channelCode', data.channelCode)
          localStorage.setItem('mgUuid', data.mgUuid)
          localStorage.setItem('id', data.id)
          localStorage.setItem('shareId', data.shareId)
          localStorage.setItem('superiorId', data.superiorId)
          localStorage.setItem('userLevel', data.userLevel)
          localStorage.setItem('isAuth', true)
          this.isVisible = false
          let url = window.location.href.split('#')[0]
          this.superiorLongUrlInvite =
            process.env.NODE_ENV == 'development'
              ? `http://test.youku.com/#/shareExtension?nickName=${encodeURIComponent(
                this.userInfo.nickName
              )}&superiorId=${this.userInfo.id}`
              : `${url}#/shareExtension?nickName=${encodeURIComponent(
                this.userInfo.nickName
              )}&superiorId=${this.userInfo.id}`
          this.getUserAccountInfo()
        }
      })
    },
    getUserAccountInfo() {
      GetUserAccountInfo().then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          this.userAccountInfo = data;
        }
      })
    },
    getShortLink(url) {
      GetShortLink({
        longLink: url
      }).then((res => {
        const { code, data } = res
        if (code == '0') {
          console.log(data)
          this.showInviteFriendsPopup = true
          this.superiorShortUrlInvite = data
        }
      }))
    },
    // 盟主订单数据汇总
    getUserOrderSummary() {
      GetUserOrdeGetAllUserOrderSummaryrSummary().then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          this.orderSummary = data || {}
        } else {
          // this.$toast(message)
        }
      })
    },
    // 我的团队人数
    getTeamList() {
      GetTeamList({
        pageIndex: 1,
        pageSize: 999999
      }).then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          this.teamListTotal = data.total || 0
        } else {
          // this.$toast(message)
        }
      })
    },
    // 点击 奖励明细 或 分佣明细
    goCommission(type) {
      if (!getToken('token')) {
        this.goLogin();
        return;
      }
      if(!getToken('isAuth')){
        this.isVisible = true;
        return;
      }
      if (this.isDisabled) return this.$toast('账号已被禁用')
      this.$router.push({
        path: '/commissionDetails',
        query: {
          type: type
        }
      })
    },
    // 去提现
    handleWithdrawal() {
      if (!getToken('token')) {
        this.goLogin();
        return;
      }
      if(!getToken('isAuth')){
        this.isVisible = true;
        return;
      }
      if (this.isDisabled) return this.$toast('账号已被禁用')
      this.$router.push({
        path: '/myCommission',
        query: {
          // price: this.userAccountInfo.balance, // 可提现金额
          nickName: this.userInfo.nickName // 盟主昵称
        }
      })
    },
    // 点击 提现记录 或 我的团队 或 活动奖励
    goItem(e) {
      if (!getToken('token')) {
        this.goLogin();
        return;
      }
      if(!getToken('isAuth')){
        this.isVisible = true;
        return;
      }
      if (this.isDisabled) return this.$toast('账号已被禁用')
      this.$router.push(e)
    },
    // 点击 联系客服
    handleCustomer() {
      this.welfareShow = true
    },

    // 点击 邀好友
    showInvite() {
      if (!getToken('token')) {
        this.goLogin();
        return;
      }
      if(!getToken('isAuth')){
        this.isVisible = true;
        return;
      }
      this.getShortLink(this.superiorLongUrlInvite)
    },
    handleClose() {
      this.showInviteFriendsPopup = false
    },
    viewAgreement(e) {
      this.$router.push(e)
    },
  }
}
</script>

<style lang="less" scoped></style>
